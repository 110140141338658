<template>
  <svg
    width="29"
    height="45"
    viewBox="0 0 29 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-7 h-10"
  >
    <path
      d="M27.9843 29.9282L14.4922 43.4204L1.00003 29.9282"
      stroke="#1E1E1E"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M27.9843 13.4922L14.4922 26.9843L1.00003 13.4922"
      stroke="#1E1E1E"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
